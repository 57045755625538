import flatpickr from "flatpickr";

export const preventNonNumericalInput = function(selector = "input[type='number']") {
  $(selector).on('keypress', (e) => {
    const charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
    const charStr = String.fromCharCode(charCode);

    if (!charStr.match(/^[0-9]+$/)) {
      e.preventDefault();
    }
  })
}


export const flatpickrBinding = function(selectors){
  $(selectors).each(function(){
    const $input = $(this).is('input') ? $(this) : $(this).find('input[data-input]');
    let today = new Date()
    let tomorrow = new Date(today)
    flatpickr(this, {
      wrap: true,
      defaultDate: $input.val() || tomorrow.setDate(tomorrow.getDate() + 1),
      disable: [
        function(date) {
          return (date <= today)
        }
      ]
    })
  })
}

export const select2Binding = function(selector, options = {}){
  const defaultOptions = {
    minimumResultsForSearch: -1,
    dropdownCssClass: "single-option",
    containerCssClass: "container-categories",
    selectionCssClass: "select-categories"
  };

  const select2Options = $.extend({}, defaultOptions, options);
  $(selector).select2(select2Options);
}

window.flatpickrBinding = flatpickrBinding;
window.select2Binding = select2Binding;

// Add custom validator to Jquery Validation
$.validator.addMethod("check_password", function(value, element, regexp) {
  var re = new RegExp(regexp);
  return this.optional(element) || re.test(value);
}, "At least 1 number, 1 lower case alphabet, 1 upper case alphabet.");

$(() => {
    preventNonNumericalInput();
  }
);

$(document).on('mouseenter', '.mightOverflow', function() {
  var $this = $(this);

  if(this.offsetWidth < this.scrollWidth && !$this.attr('title')){
      $this.attr('title', $this.text());
  }
});
