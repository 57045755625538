window.SignUp = {
  init() {
    this.validateForm();
    this.showPassword();
  },

  validateForm() {
    $("#signup-form").validate({
      errorClass: "is-invalid",
      rules: {
        "user[name]": {
          required: true
        },
        "user[email]": {
          required: true,
          email: true
        },
        "user[password]": {
          required: true,
          minlength: 8,
          check_password: /((?=.*\d)(?=.*[A-Z])(?=.*[a-z]))/
        },
        "agree-condition": {
          required: true
        }
      },
      messages: {
        "agree-condition": "You must accept terms and conditions."
      },
      errorPlacement: function(error, element) {
        if (element.attr("name") == "agree-condition") {
          error.appendTo($(element).parents('.custom-checkbox'));
        } else {
          error.insertAfter(element);
        }
      }
    });
  },

  showPassword() {
    $('#show-password').on('mousedown touchstart', () => {
      $('#user_password').attr('type', 'text');
    }).on('mouseup touchend', () => {
      $('#user_password').attr('type', 'password');
    });
  }
};
