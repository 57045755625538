import flatpickr from "flatpickr";

window.SPProfileShow = {
  init() {
    this.initSlickCarousel('.videos-container')
    this.handleReadMore('bio-content', 'bio-wrapper')
    this.handleReadMore('equipment-list-content', 'equipment-list-wrapper')
    this.bindDatePicker()
    this.initProjectSelection()
    this.validateFormQuotationForm()
    this.handleMultipleModalScrollbar()
  },

  initSlickCarousel(className) {
    $(className).slick({
      infinite: true,
      draggable: false,
      slidesToShow: 3,
      dots: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    })

    this.handleReadMore('bio-content', 'bio-wrapper');
    this.handleReadMore('equipment-list-content', 'equipment-list-wrapper');
    this.bindingAddExtraOptions();
  },

  handleReadMore(contentClass, wrapperClass) {
    var contentEle, wrapperEle, readMoreBtn

    contentEle = $(`.${contentClass}`)[0]
    wrapperEle = $(`.${wrapperClass}`)[0]
    readMoreBtn = $(`.${wrapperClass} .read-more .button`)[0]

    if (contentEle && wrapperEle && (contentEle.clientHeight > wrapperEle.clientHeight)) {
      $(readMoreBtn.parentElement).show()
    }

    if (readMoreBtn) {
      $(readMoreBtn).on('click', (e) => {
        e.preventDefault()
        if (contentEle.clientHeight > wrapperEle.clientHeight) {
          $(readMoreBtn.parentElement).hide()
          $(wrapperEle).css({
            maxHeight: 'none'
          })
        }
      })
    }
  },

  bindingAddExtraOptions() {
    $('body').on('click', '.addExtra', function () {
      $(this).parents('.sp-service-optional').addClass('selected');
      $(this).parents('.sp-service-optional').find('.extra-service-id').val($(this).data().serviceExtraOptionalId);
    });

    $('body').on('click', '.removeExtra', function () {
      $(this).parents('.sp-service-optional').removeClass('selected');
      $(this).parents('.sp-service-optional').find('.extra-service-id').val('');
    });
  },

  bindDatePicker() {
    let today = new Date()
    let tomorrow = new Date(today)

    flatpickr(
      ".flatpickr.group-booking-date",
      {
        wrap: true,
        defaultDate: tomorrow.setDate(tomorrow.getDate() + 1),
        disable: [
          function(date) {
            return (date <= today)
          }
        ]
      }
    );
    flatpickr(".flatpickr.group-booking-time",
      {
        wrap: true,
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i"
      }
    );
  },

  initProjectSelection(selector = "#projectSelection") {
    $(selector).select2({
      dropdownCssClass: "single-option",
      containerCssClass: "container-categories",
      selectionCssClass: "select-categories",
      placeholder: "Select an option",
      minimumResultsForSearch: -1
    }).on('select2:open', () => {
      const addNewProjectButtonHtml = `
        <div role="button" data-toggle="modal" data-target="#newProjectModal" class="create-new-project-btn add-more-btn">
          Create New Project
        </div>
      `;
      $(".select2-results:not(:has(div))").append(addNewProjectButtonHtml);
      $('.create-new-project-btn').off('click').click(() => $(selector).select2('close'));
    })
  },

  validateFormQuotationForm() {
    $("#create-quotation-form").validate({
      errorClass: "is-invalid",
      rules: {
        "quotation[project_id]": {
          required: true
        },
        "quotation[requirement]": {
          required: true
        },
        "quotation[booking_date]": {
          required: true
        },
        "quotation[booking_time]": {
          required: true
        }
      }
    })
  },

  bindDatePicker() {
    flatpickr(".flatpickr.group-booking-date", { wrap: true });
    flatpickr(".flatpickr.group-booking-time",
      {
        wrap: true,
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i"
      }
    );
  },

  addNewProjectOption(project) {
    const newOption = new Option(project.name, project.id, false, false);
    const newSelectedOption = new Option(project.name, project.id, true, true);
    $(".project-selection:visible").append(newSelectedOption).trigger('change');
    $(".project-selection:not(:visible)").append(newOption).trigger('change');
  },

  bindSelect2BookingTime(selector, options = {}){
    const defaultOptions = {
      minimumResultsForSearch: -1,
      dropdownCssClass: "single-option",
      containerCssClass: "container-categories",
      selectionCssClass: "select-categories",
      templateResult: function(item) {
        if(item.disabled) {
          return $(`
            <div class="row start-time-row">
              <div class="col-6">
                <span>${item.text}</span>
              </div>
              <div class="col-6 text-right px-0">
                <label>Unavailable</label>
              </div>
            </div>
          `)
        } else {
          return item.text
        }
      }
    };

    const select2Options = $.extend({}, defaultOptions, options);
    $(selector).select2(select2Options);
  },

  handleMultipleModalScrollbar() {
    $('.modal').on("hidden.bs.modal", function (e) {
      if ($('.modal:visible').length) {
        $('body').addClass('modal-open');
      }
    })
  }
}
