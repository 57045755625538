window.SpProfile = {
  init() {
    this.initCategoriesSelector();
    this.initCountiesSelector();
    this.validateForm();
    this.previewSpProfileImage();
    this.handleSubmitCoverPhotos();
    this.handleDeleteCoverPhoto();
  },

  initCategoriesSelector() {
    $('#sp_profile_categories').select2({
      dropdownCssClass: "option-categories",
      containerCssClass: "container-categories",
      selectionCssClass: "select-categories",
      placeholder: "Select an option",
      multiple: true,
      closeOnSelect : false,
      tags: true
    });
  },

  initCountiesSelector() {
    $('#sp_profile_counties').select2({
      dropdownCssClass: "single-option",
      containerCssClass: "container-categories",
      selectionCssClass: "select-categories",
      placeholder: "Select an option",
      minimumResultsForSearch: -1
    });
  },

  validateForm() {
    $("#create-sp-profile-form").validate({
      errorClass: "is-invalid",
      rules: {
        "sp_profile[profile_name]": {
          required: true
        },
        // "sp_profile[address]": {
        //   required: true
        // }
      }
    });
  },

  previewSpProfileImage() {
    $('#change-img-btn').on('click', function(e){
      e.preventDefault();
      $('#insert-image').click();
    })

    $('#insert-image').on('change', function(){
      if (this.files && this.files[0]) {
        var reader = new FileReader();

        reader.onload = function(e) {
          $('#img-camera-wrapper').addClass('rounded')
          $('.img-camera').addClass('w-100 h-100')
          $('.img-camera').attr('src', e.target.result);
          $('#change-img-btn').removeClass('d-none')
        }

        reader.readAsDataURL(this.files[0]);
      }
    })
  },

  handleSubmitCoverPhotos() {
    $fileList = $('#upload-cover-photos')

    $fileList.on('change', (e) => {
      if($fileList[0].files.length + $('.cover-photo').length <= 8) {

        for (i = 0; i < $fileList[0].files.length ; i++) {
          let formData = new FormData();

          formData.append('sp_profile_cover_photos[cover_image]', $fileList[0].files[i]);

          $.ajax({
            url : '/sp_profile_cover_photos',
            type : 'POST',
            data : formData,
            processData: false,
            contentType: false,
            success: () => {
              this.handleDeleteCoverPhoto();
            }
          });
        }
      } else {
        alert('Cover photos cannot be more than 8.')
      }
    })
  },

  handleDeleteCoverPhoto() {
    $('.delete-cover-photo-btn').on('click', (e) => {
      $(e.currentTarget).closest('.cover-photo').remove()
    })
  },
}
