window.ResetPassword = {
  init() {
    this.validateForm();
  },

  validateForm() {
    $("#reset-passsword-form").validate({
      errorClass: "is-invalid",
      rules: {
        "user[email]": {
          required: true,
          email: true
        }
      },
      errorPlacement: function(error, element) {
        error.insertAfter(element);
      }
    });
  }
};