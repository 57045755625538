window.paymentShow = {
  init(stripePublicKeyId) {
    this.stripePublicKeyId = stripePublicKeyId;
    this.mountStripe()
    this.handleClickPayBtn()

    $('body').on('change', '#useExistCards', function () {
      $('#card-list').removeClass('d-none')
      $('#new-card-details').addClass('d-none')
    })

    $('body').on('change', '#useNewCard', function () {
      $('#card-list').addClass('d-none')
      $('#new-card-details').removeClass('d-none')
    })
  },

  mountStripe() {
    let style = {
      base: {
        color: 'white',
        lineHeight: '40px',
        fontWeight: 600,
        fontFamily: 'Spartan',
        fontSize: '15px',

        '::placeholder': {
          color: '#8B8B8B',
        },
      },
    };

    this.stripe = Stripe(this.stripePublicKeyId);
    const elements = this.stripe.elements();

    this.cardNumberElement = elements.create('cardNumber', {
      style: style,
      placeholder: 'Enter...'
    });

    this.cardNumberElement.mount('#cardNumber');

    const cardExpiryElement = elements.create('cardExpiry', {
      style: style,
      placeholder: 'MM/YY'
    });
    cardExpiryElement.mount('#cardExpiry');

    const cardCvcElement = elements.create('cardCvc', {
      style: style,
      placeholder: 'CVV'
    });
    cardCvcElement.mount('#cardCvc');
  },

  showProgressCursor() {
    $.blockUI({
      css: {
        backgroundColor: 'transparent',
        border: 'none'
      },
      message: '<div class="spinner-border text-orange" role="status"><span class="sr-only"></span></div>',
      baseZ: 1500,
      overlayCSS: {
        opacity: 0.7,
        cursor: 'wait'
      }
    });
  },

  handleClickPayBtn() {
    $('#new-card-details button.btn.btn-primary.pay-btn').on('click', () => {
      $('button.btn.btn-primary.pay-btn').attr('disabled', 'disabled');
      $('#server-message').empty();
      this.showProgressCursor();

      var clientSecret = $('#new-card-details form').data('secret');
      this.stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: {
            card: this.cardNumberElement
          },
        }
      ).then(function(result) {
        if (result.error) {
          const errorElement = $('#flash-wrapper span:first');
          $('#flash-wrapper').addClass('d-none');
          errorElement.text(result.error.message)
          $('#flash-wrapper').removeClass('d-none');
          $('button.btn.btn-primary.pay-btn').removeAttr('disabled');
          $.unblockUI();
        } else {
          $('#flash-wrapper').addClass('d-none');
          $('#paymentMethod').attr('value', result.setupIntent.payment_method);
          $('#submitBookingFormNewCard').trigger('click');
        }
      })
    })

    $('#card-list button.btn.btn-primary.pay-btn').on('click', () => {
      $('button.btn.btn-primary.pay-btn').attr('disabled', 'disabled');
      this.showProgressCursor();
      $('#card-list #submitBookingFormExistingCard').trigger('click');
    })
  }
}
