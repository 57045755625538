// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "@fortawesome/fontawesome-free/css/all"

require('jquery')
require("bootstrap")

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import "jquery-validation"
import "./vendors/select2.full.min"
import "./vendors/bootstrap-slider.min"
import "./vendors/jquery.blockUI.min"

import './utils'
import './sign_up'
import './login'
import './reset_password'
import './change_password'
import './projects'
import './sp_profiles'
import './sp_services'
import './sp_profiles_show'
import './project_show'
import './vendors/slick.min.js'
import './service_providers'
import './calendar'
import './users_setting'
import './payment_show'
import './project_files'
import './sp_profiles_edit'
import './quotation_new'
import './upload_showreel'
import './project_services'

Rails.start()
ActiveStorage.start()
