import flatpickr from "flatpickr";

window.SpService = {
  init() {
    this.serviceIndex = 1;
    this.initBookingDepositSlider();
    this.initSpecificStartTimeFlatPr();
    this.previewSpProfileImage();
    this.handleAddAnotherStartTime();
    this.handleAddOptionalExtra();
    this.handleEditOptionalExtra();
    this.handleDeleteOptioExtra();
    this.handleAddService();
    this.handleDeleteService();
    this.handleToggleStartTime();
    this.handleDeteleAnotherStartTime();
  },

  initBookingDepositSlider() {
    $('.service-provider-steps .booking-deposit-slider').bootstrapSlider();
  },

  initSpecificStartTimeFlatPr() {
    flatpickr(".flatpickr", {
      wrap: true,
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
    });
  },

  previewSpProfileImage() {
    $('.service-provider-steps').on('click', '.insert-image-btn', function(e) {
      let fileInput = $(e.currentTarget).siblings('.insert-image')
      let previewImage = $(e.currentTarget).siblings('.img-camera')

      fileInput.click()
      fileInput.on('change', function(e){
        if (this.files && this.files[0]) {
          var reader = new FileReader();

          reader.onload = function(e) {
            previewImage.addClass('rounded')
            previewImage.attr('src', e.target.result);
          }

          reader.readAsDataURL(this.files[0]);
        }
      })
    })
  },

  handleAddAnotherStartTime() {
    $('.service-provider-steps').on('click', '.add-another-btn', function(e) {
      let startTime = `
        <div class="start-time mb-2 d-flex align-items-center">
          <div class="input-group input-group-merge flatpickr">
            <input class="form-control form-control-prepend flatpickr-input" data-input="" name="sp_services[][sp_service_start_times][]" placeholder="Time" type="text" readonly="readonly">
            <div class="input-group-prepend">
              <span class="input-group-text input-button" data-toggle="">
                <i data-feather="clock"></i>
              </span>
            </div>
          </div>
          <div class="remove-another-btn d-flex align-items-center">
            <i class="ml-2" data-feather="minus-circle"></i>
            <span class="ml-1 form-control-label">Remove</span>
          </div>
        </div>
      `

      let $destination = $(this).parents('.group-add-another').siblings('.add-another-start-time-section');
      $destination.append(startTime);
      window.SpService.initSpecificStartTimeFlatPr()
      feather.replace()
    })
  },

  handleDeteleAnotherStartTime() {
    $('.service-provider-steps').on('click', '.remove-another-btn', function(e) {
      $(e.currentTarget).closest('.start-time').remove()
    })
  },

  handleAddOptionalExtra() {
    $('.service-provider-steps').on('click', '.open-optional-extra-modal', function(e) {
      let $destination = $(this).parents('.group-add-options').siblings('.option-extra-table').find('table tbody');
      $('#optional-extra-form')[0].reset()

      $('#add-optional-extra').off('click').on('click', function() {
        let option = `
          <tr class="extra-optional mt-2">
            <input class="service-name" type="hidden" name="sp_services[][sp_service_optionals][][name]" value="${$('#modal-service-name').val()}">
            <input class="additional-cost" type="hidden" name="sp_services[][sp_service_optionals][][price]" value="${$('#modal-additional-cost').val()}">
            <td class="p-0 text-white font-weight-bold service-name">
              ${$('#modal-service-name').val()}
            </td>
            <td class="p-0 text-white font-weight-bold additional-cost">
              <span>+€</span>
              <span>${$('#modal-additional-cost').val()}</span></td>
            <td class="text-right text-white p-0">
              <button class="feather-icon" type="button">
                <i class="edit-optional-extra" data-feather="edit"></i>
              </button>
              <button class="feather-icon pr-1" type="button">
                <i class="delete-optional-extra" data-feather="trash-2"></i>
              </button>
            </td>
          </tr>
        `

        $destination.append(option)
        $('#addOptionalExtraModal').modal('hide')
        $(".modal-backdrop.show").hide();
        feather.replace()
      })
    })
  },

  handleEditOptionalExtra() {
    $('.service-provider-steps').on('click', '.edit-optional-extra', function(e) {
      let serviceName = $(e.currentTarget).closest('.extra-optional').find('.service-name')
      let additionalCost = $(e.currentTarget).closest('.extra-optional').find('.additional-cost span:last-child')
      let serviceNameInput = $(e.currentTarget).closest('.extra-optional').find('input.service-name')
      let additionalCostInput = $(e.currentTarget).closest('.extra-optional').find('input.additional-cost')

      $('#addOptionalExtraModal').modal('show')
      $('#modal-service-name').val(serviceName.text().trim())
      $('#modal-additional-cost').val(additionalCost.text())

      $('#add-optional-extra').off('click').on('click', function() {
        serviceName.text($('#modal-service-name').val())
        additionalCost.text($('#modal-additional-cost').val())
        serviceNameInput.val($('#modal-service-name').val())
        additionalCostInput.val($('#modal-additional-cost').val())
        $('#addOptionalExtraModal').modal('hide')
        $(".modal-backdrop.show").hide();
      })
    })
  },

  handleDeleteOptioExtra() {
    $('.service-provider-steps').on('click', '.delete-optional-extra', function(e) {
      $(e.currentTarget).closest('.extra-optional').remove()
    })
  },

  handleAddService() {
    $('#add-service-card-section a').on('click', () => {
      window.SpService.serviceIndex += 1
      $('#service-card-section').append($('#clone-service-card .service-card').clone())
      $('#service-card-section .service-card:last .service-index').text(`Service #${window.SpService.serviceIndex}`)
      this.initBookingDepositSlider()
      this.initSpecificStartTimeFlatPr()
    })
  },

  handleDeleteService() {
    $('.service-provider-steps').on('click', '.close' ,function(e) {
      window.SpService.serviceIndex -= 1

      //if($('#service-card-section .service-card').length > 1) {
        let $serviceCard = $(e.currentTarget).closest('.service-card')
        if($serviceCard.find('.service-id').val()) {
          $serviceCard.hide()
          $serviceCard.find('.input-destroy').val(1)
        } else {
          $serviceCard.remove()

          for (let i = 0; i < $('#service-card-section .service-card').length; i++) {
            $($('#service-card-section .service-card')[i]).find('.service-index').text(`Service #${i + 1}`)
          }
        }
      // } else {
      //   alert('You need to have at least one service.')
      // }
    })
  },

  handleToggleStartTime() {
    $('.service-provider-steps').on('change', 'input.specific-time-toggle', function(e) {
      if($(e.currentTarget).prop('checked')) {
        $(e.currentTarget).parent().siblings('.add-another-start-time-section, .group-add-another').removeClass('d-none')
      } else {
        $(e.currentTarget).parent().siblings('.add-another-start-time-section, .group-add-another').addClass('d-none')
      }
    })
  }
}
