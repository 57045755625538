window.Projects = {
  init() {
    this.validateForm();
    this.disabledFormSubmitButton();
  },

  validateForm() {
    $("#create-project-form").validate({
      errorClass: "is-invalid",
      rules: {
        "project[name]": {
          required: true
        }
      }
    });
  },

  disabledFormSubmitButton() {
    $('#create-project-form').on('submit', function(e) {
      $(e.currentTarget).find('button[type=submit]').attr("disabled", true);
      $.blockUI({
        css: {
          backgroundColor: 'transparent',
          border: 'none'
        },
        message: '<div class="spinner-border text-orange" role="status"><span class="sr-only"></span></div>',
        baseZ: 1500,
        overlayCSS: {
          opacity: 0.7,
          cursor: 'wait'
        }
      });
    })
  }
}
