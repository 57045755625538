window.projectServices = {
  init(stripePublicKeyId) {
    this.stripePublicKeyId = stripePublicKeyId;
    this.mountStripe();
    this.handleFormAction();
    this.handleFormSubmit();

    $('body').on('change', '#useExistCards', function () {
      $('#card-list').removeClass('d-none').addClass('visible')
      $('#new-card-details').addClass('d-none').removeClass('visible')
    })

    $('body').on('change', '#useNewCard', function () {
      $('#card-list').addClass('d-none').removeClass('visible')
      $('#new-card-details').removeClass('d-none').addClass('visible')
    })

    $('body').on('hidden.bs.modal', function () {
      $('.pay-remaining-btn').removeAttr('disabled');
      $('.payment-remaining-form').removeAttr('action');
    });
  },

  mountStripe() {
    let style = {
      base: {
        color: 'white',
        lineHeight: '40px',
        fontWeight: 600,
        fontFamily: 'Spartan',
        fontSize: '15px',

        '::placeholder': {
          color: '#8B8B8B',
        },
      },
    };

    this.stripe = Stripe(this.stripePublicKeyId);
    const elements = this.stripe.elements();

    this.cardNumberElement = elements.create('cardNumber', {
      style: style,
      placeholder: 'Enter...'
    });

    this.cardNumberElement.mount('#cardNumber');

    const cardExpiryElement = elements.create('cardExpiry', {
      style: style,
      placeholder: 'MM/YY'
    });
    cardExpiryElement.mount('#cardExpiry');

    const cardCvcElement = elements.create('cardCvc', {
      style: style,
      placeholder: 'CVV'
    });
    cardCvcElement.mount('#cardCvc');
  },

  handleFormAction() {
    $('.pay-balance-btn').on('click', function(e){
      action = $(e.currentTarget).attr('data-href')
      $('.payment-remaining-form').attr('action', action)
    })
  },

  showProgressCursor() {
    $.blockUI({
      css: {
        backgroundColor: 'transparent',
        border: 'none'
      },
      message: '<div class="spinner-border text-orange" role="status"><span class="sr-only"></span></div>',
      baseZ: 1500,
      overlayCSS: {
        opacity: 0.7,
        cursor: 'wait'
      }
    });
  },

  handleFormSubmit() {
    let scopeThis = this;
    $('.pay-remaining-btn').on('click',  function(e) {
      $(e.currentTarget).attr('disabled', 'disabled');
      $('#server-message').empty();
      scopeThis.showProgressCursor();

      if ($('.visible').is('#new-card-details')) {
        var clientSecret = $('#new-card-details form').data('secret');
        this.stripe.confirmCardSetup(
          clientSecret,
          {
            payment_method: {
              card: this.cardNumberElement
            },
          }
        ).then(function(result) {
          if (result.error) {
            const errorElement = $('#pay-remaining-modal #flash-wrapper span:first');
            $('#pay-remaining-modal #flash-wrapper').addClass('d-none');
            errorElement.text(result.error.message)
            $('#pay-remaining-modal #flash-wrapper').removeClass('d-none');
            $('.pay-remaining-btn').removeAttr('disabled');
            $.unblockUI();
          } else {
            $('#pay-remaining-modal #flash-wrapper').addClass('d-none');
            $('#paymentMethod').attr('value', result.setupIntent.payment_method)
            $('.visible .submit-btn').trigger('click')
          }
        })
      } else {
        $('.visible .submit-btn').trigger('click')
      }
    })
  }
}
