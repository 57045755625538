window.quotationNew = {
  init() {
    this.initBookingDepositSlider();
    this.initBookingTimeFlatPr();
    this.initBookingDateFlatPr();
    this.handleAddOptionalExtra();
    this.handleSaveOptionalExtra();
    this.handleEditOptionalExtra();
    this.handleDeleteOptionalExtra();
    this.handleDeleteQuotationService();
    this.calculateQuotationTotalAndTotalDueAmount();
    this.handleUpdateDueTotalValue();
  },

  initBookingTimeFlatPr() {
    flatpickr(".flatpickr.time", {
      wrap: true,
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
    });
  },

  initBookingDateFlatPr() {
    let today = new Date()
    let tomorrow = new Date(today)

    flatpickr(".flatpickr.date", {
      wrap: true,
      altInput: true,
      altFormat: "j M, Y",
      dateFormat: "Y-m-d",
      defaultDate: tomorrow.setDate(tomorrow.getDate() + 1),
      disable: [
        function(date) {
          return (date <= today)
        }
      ]
    });
  },

  initBookingDepositSlider() {
    $('.booking-deposit-slider').bootstrapSlider()
  },

  handleAddOptionalExtra() {
    $('.add-optional-extra').off('click').on('click', function(e) {
      let $destination = $(this).parents('.group-add-options').siblings('.option-extra-table').find('table tbody');

      let option = `
        <tr class="extra-optional mt-2">
          <td class="p-0 text-white font-weight-bold service-name w-25 align-middle">
            <input type="text" name="quotation_service[quotation_service_optionals][][name]" value="" class="form-control border-0 shadow-none bg-transparent pl-0" placeholder="Studio A: Half Day">
          </td>
          <td class="p-0 text-white font-weight-bold additional-cost align-middle">
            <input type="number" name="quotation_service[quotation_service_optionals][][price]" value="" class="form-control border-0 shadow-none bg-transparent pl-0" placeholder="Price">
          </td>
          <td class="text-right text-white pr-2 action">
            <i class="save-optional-extra" data-feather="save">
          </td>
        </tr>
      `

      $destination.append(option)
      feather.replace()
    })
  },

  handleSaveOptionalExtra(){
    $('.quotation-service-modal').off('click', '.save-optional-extra');
    $('.quotation-service-modal').on('click', '.save-optional-extra', function(e){
      let $destination = $(e.currentTarget).parents('tr.extra-optional')
      let name = $(e.currentTarget).parents('td').siblings('.service-name').find('input').val()
      let price = $(e.currentTarget).parents('td').siblings('.additional-cost').find('input').val()

      if (name && price > 0) {
        let option = `
          <td class="p-0 text-white font-weight-bold service-name w-25 align-middle">
            <input type="hidden" name="quotation_service[quotation_service_optionals][][name]" value="${name}">
            <span class="name">${name}</span>
          </td>
          <td class="p-0 text-white font-weight-bold additional-cost align-middle">
            <input type="hidden" name="quotation_service[quotation_service_optionals][][price]" value="${price}">
            <span>+€</span>
            <span class="price">${price}</span>
          </td>
          <td class="text-right text-white pr-2 action">
            <i class="edit-optional-extra mr-2" data-feather="edit"></i>
            <i class="delete-optional-extra" data-feather="trash-2"></i>
          </td>
        `

        $destination.html(option)
        feather.replace()
      } else {
        alert('Please enter needed fields.')
      }
    })
  },

  handleEditOptionalExtra() {
    $('.quotation-service-modal').on('click', '.edit-optional-extra', function(e) {
      let $destination = $(e.currentTarget).parents('tr.extra-optional')
      let name = $(e.currentTarget).parents('td').siblings('.service-name').find('span.name').text()
      let price = $(e.currentTarget).parents('td').siblings('.additional-cost').find('span.price').text()

      let option = `
        <td class="p-0 text-white font-weight-bold service-name w-25">
          <input type="text" name="quotation_service[quotation_service_optionals][][name]" value="${name}" class="form-control border-0 shadow-none bg-transparent pl-0" placeholder="Studio A: Half Day">
        </td>
        <td class="p-0 text-white font-weight-bold additional-cost">
          <input type="number" name="quotation_service[quotation_service_optionals][][price]" value="${price}" class="form-control border-0 shadow-none bg-transparent pl-0" placeholder="Price">
        </td>
        <td class="text-right text-white pr-2 action">
          <i class="save-optional-extra" data-feather="save">
        </td>
      `

      $destination.html(option)
      feather.replace()
    })
  },

  handleDeleteOptionalExtra() {
    $('.quotation-service-modal').on('click', '.delete-optional-extra', function(e) {
      $(e.currentTarget).closest('.extra-optional').remove()
    })
  },

  handleDeleteQuotationService() {
    $('.remove-quotation-service').on('click', function(e) {
      $(e.currentTarget).closest('.service-wrapper').remove()
    })
  },

  calculateQuotationTotalAndTotalDueAmount() {
    let quotation_service_total = 0
    let quotation_service_optionals_total = 0

    for(i=0; i < $('.quotation-service-value').length; i++ ) {
      quotation_service_total += parseInt($('.quotation-service-value')[i].innerHTML.replace('€', ''))
    }

    for(i=0; i < $('.quotation-service-optional-value').length; i++ ) {
      quotation_service_optionals_total += parseInt($('.quotation-service-optional-value')[i].innerHTML)
    }

    let total = quotation_service_total + quotation_service_optionals_total
    let dueTotal = total * (parseInt($('.booking-deposit-slider').attr('data-value')) / 100)

    $('#quotation_total_amount').val(total)
    $('.total-value span:last').text(total)
    $('.due-total-value span:last').text(dueTotal)
  },

  handleUpdateDueTotalValue() {
    $('.booking-deposit-slider').on('slideStop', function(){
      window.quotationNew.calculateQuotationTotalAndTotalDueAmount()
    })
  }
}
