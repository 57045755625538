window.Login = {
  init() {
    this.validateForm();
  },

  validateForm() {
    $("#login-form").validate({
      errorClass: "is-invalid",
      rules: {
        "user[email]": {
          required: true,
          email: true
        },
        "user[password]": {
          required: true,
          minlength: 6
        }
      },
      errorPlacement: function(error, element) {
        error.insertAfter(element);
      }
    });
  }
};
