import flatpickr from "flatpickr";

window.ServiceProviders = {
  init() {
    this.bindDatePicker();
    this.initLocationSelect2();
  },

  bindDatePicker() {
    flatpickr(".flatpickr", { wrap: true });
  },

  initLocationSelect2() {
    $('#county_id').select2({
      dropdownCssClass: "single-option",
      containerCssClass: "container-categories",
      selectionCssClass: "select-categories",
      placeholder: {
        id: '',
        text: "Select an option"
      },
      allowClear: true
    });

    $('#county_id').on("change", function(e) {
      $(e.target).parents('form')[0].submit();
    });
  }
}
