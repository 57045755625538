window.userSettings = {
  init(stripePublicKeyId) {
    this.stripePublicKeyId = stripePublicKeyId;
    this.previewSpProfileImage();
    this.validateForm();
    this.validateModalForm();
    this.bindStripeForm();
    this.handleOpenAddCardModal();
  },

  previewSpProfileImage() {
    $('#change-img-btn').on('click', function(e) {
      let fileInput = $('#change-image')
      let previewImage = $('.user-avatar')
      let filePath = $('#avatar_path')

      fileInput.on('change', function(e){
        if (this.files && this.files[0]) {
          var reader = new FileReader();

          reader.onload = function(e) {
            previewImage.attr('src', e.target.result);
          }

          filePath.text(this.files[0].name)
          reader.readAsDataURL(this.files[0]);
        }
      })
    })
  },

  validateForm() {
    $("#update-user-details").validate({
      errorClass: "is-invalid",
      rules: {
        "user[name]": {
          required: true
        },
        "user[email]": {
          required: true,
          email: true
        }
      },
      errorPlacement: function(error, element) {
        error.insertAfter(element);
      }
    });
  },

  validateModalForm() {
    $("#change-password-form").validate({
      errorClass: "is-invalid",
      rules: {
        "user[password]": {
          required: true,
          minlength: 8,
          check_password: /((?=.*\d)(?=.*[A-Z])(?=.*[a-z]))/
        },
        "user[password_confirmation]": {
          required: true,
          minlength: 8,
          equalTo : "#user_password"
        }
      },
      errorPlacement: function(error, element) {
        error.insertAfter(element);
      }
    });
  },

  setOutCome(result) {
    var errorElement = $('#flash-wrapper span:first');
    $('#flash-wrapper').addClass('d-none');

    if (result.setupIntent && result.setupIntent.payment_method) {
      $.ajax({
        url: '/credit_cards',
        type: 'POST',
        data: {
          token: result.setupIntent.payment_method
        }
      })
    } else if (result.error) {
      errorElement.text(result.error.message)
      $('#flash-wrapper').removeClass('d-none');
    }
  },

  bindStripeForm() {
    let style = {
      base: {
        iconColor: 'white',
        color: 'white',
        lineHeight: '40px',
        fontWeight: 600,
        fontFamily: 'Spartan',
        fontSize: '15px',

        '::placeholder': {
          color: '#8B8B8B',
        },
      },
    };

    let stripe = Stripe(this.stripePublicKeyId);
    let elements = stripe.elements();

    let cardNumberElement = elements.create('cardNumber', {
      style: style,
      placeholder: 'Enter...'
    });

    cardNumberElement.mount('#card-number-element');
    cardNumberElement.on('change', function(event) {
      this.userSettings.setOutCome(event);
    });

    let cardExpiryElement = elements.create('cardExpiry', {
      style: style,
      placeholder: 'MM/YY'
    });
    cardExpiryElement.mount('#card-expiry-element');
    cardExpiryElement.on('change', function(event) {
      this.userSettings.setOutCome(event);
    });

    var cardCvcElement = elements.create('cardCvc', {
      style: style,
      placeholder: 'CVV'
    });
    cardCvcElement.mount('#card-cvc-element');
    cardCvcElement.on('change', function(event) {
      this.userSettings.setOutCome(event);
    });

    window.userSettings.handleSubmitAddCard(stripe, cardNumberElement)
  },

  handleSubmitAddCard(stripe, cardNumberElement) {
    $('#add-credit-card-form').on('submit', (e) => {
      e.preventDefault();
      this.showProgressCursor();

      var options = {
        name: $('#name').val(),
      };

      var clientSecret = $('#add-credit-card-form').data('secret');
      stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: {
            card: cardNumberElement
          },
        }
      ).then(function(result) {
        if (result.error) {
          alert(result.error.message);
          $.unblockUI();
        } else {
          console.log(result);
          window.userSettings.setOutCome(result);
        }
      })
    });
  },

  handleOpenAddCardModal() {
    $('#add-card').on('click', function() {
      window.$('#addCardModal').modal();
    })
  },

  showProgressCursor() {
    $.blockUI({
      css: {
        backgroundColor: 'transparent',
        border: 'none'
      },
      message: '<div class="spinner-border text-orange" role="status"><span class="sr-only"></span></div>',
      baseZ: 1500,
      overlayCSS: {
        opacity: 0.7,
        cursor: 'wait'
      }
    });
  }
}
