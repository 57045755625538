window.spProfileEdit = {
  init() {
    this.handlePreviewAvatarPath();
    this.handleSubmitCoverPhotos();
    this.handleDeleteCoverPhoto();
    this.initBookingDepositSlider();
    this.initSpecificStartTimeFlatPr();
    this.previewSpProfileImage();
    this.handleAddAnotherStartTime();
    this.handleAddOptionalExtra();
    this.handleEditOptionalExtra();
    this.handleDeleteOptionalExtra();
    this.handleToggleStartTime();
    this.handleDeteleAnotherStartTime();
    this.handleSaveOptionalExtra();
    this.handleDeleteSpProfileVideo();
    this.handleEditSpProfileVideo();
    this.handleAddSpProfileVideo();
    this.initCategoriesSelector();
  },

  handlePreviewAvatarPath() {
    $('#change-img-btn').on('click', (e) => {
      let fileInput = $('#change-image')
      let previewImage = $('.user-avatar')
      let filePath = $('#avatar_path')

      fileInput.on('change', function(e){
        if (this.files && this.files[0]) {
          var reader = new FileReader();

          reader.onload = function(e) {
            previewImage.attr('src', e.target.result);
          }

          filePath.text(this.files[0].name)
          reader.readAsDataURL(this.files[0]);
        }
      })
    })
  },

  handleSubmitCoverPhotos() {
    $fileList = $('#upload-cover-photos')

    $fileList.on('change', (e) => {
      if($fileList[0].files.length + $('.cover-photo').length <= 8) {

        for (i = 0; i < $fileList[0].files.length ; i++) {
          let formData = new FormData();

          formData.append('sp_profile_cover_photos[cover_image]', $fileList[0].files[i]);

          $.ajax({
            url : '/sp_profile_cover_photos',
            type : 'POST',
            data : formData,
            processData: false,
            contentType: false,
            success: () => {
              this.handleDeleteCoverPhoto();
            }
          });
        }
      } else {
        alert('Cover photos cannot be more than 8.')
      }
    })
  },

  handleDeleteCoverPhoto() {
    $('.delete-cover-photo-btn').on('click', (e) => {
      $(e.currentTarget).closest('.cover-photo').remove()
    })
  },

  initBookingDepositSlider() {
    $('.edit-service-modal').on('shown.bs.modal', function(){
      $(this).find('.booking-deposit-slider').bootstrapSlider();
    });
  },

  initSpecificStartTimeFlatPr() {
    flatpickr(".flatpickr", {
      wrap: true,
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i"
    });
  },

  previewSpProfileImage() {
    $('.edit-service-modal').on('click', '.insert-image-btn', function(e) {
      let fileInput = $(e.currentTarget).siblings('.insert-image')
      let previewImage = $(e.currentTarget).siblings('.img-camera')
      let imagePath = $(e.currentTarget).siblings('.image-path')

      fileInput.on('change', function(e){
        if (this.files && this.files[0]) {
          var reader = new FileReader();

          reader.onload = function(e) {
            previewImage.addClass('rounded')
            previewImage.attr('src', e.target.result);
          }

          imagePath.text(this.files[0].name)
          reader.readAsDataURL(this.files[0]);
        }
      })
    })
  },

  handleAddAnotherStartTime() {
    $('.edit-service-modal').on('click', '.add-another-btn', function(e) {
      let startTime = `
        <div class="start-time mb-2 d-flex align-items-center">
          <div class="input-group input-group-merge flatpickr">
            <input class="form-control form-control-prepend flatpickr-input" data-input="" name="sp_service[sp_service_start_times][]" placeholder="Time" type="text" readonly="readonly" value="12:00">
            <div class="input-group-prepend">
              <span class="input-group-text input-button" data-toggle="">
                <i data-feather="clock"></i>
              </span>
            </div>
          </div>
          <div class="remove-another-btn d-flex align-items-center">
            <i class="ml-2" data-feather="minus-circle"></i>
            <span class="ml-1 form-control-label">Remove</span>
          </div>
        </div>
      `

      let $destination = $(this).parents('.group-add-another').siblings('.add-another-start-time-section');
      $destination.append(startTime);
      window.SpService.initSpecificStartTimeFlatPr()
      feather.replace()
    })
  },

  handleDeteleAnotherStartTime() {
    $('.edit-service-modal').on('click', '.remove-another-btn', function(e) {
      $(e.currentTarget).closest('.start-time').remove()
    })
  },

  handleAddOptionalExtra() {
    $('.add-optional-extra').on('click', function(e) {
      let $destination = $(this).parents('.group-add-options').siblings('.option-extra-table').find('table tbody');

      let option = `
        <tr class="extra-optional mt-2">
          <td class="p-0 text-white font-weight-bold service-name w-25 align-middle">
            <input type="text" name="sp_service[sp_service_optionals][][name]" value="" class="form-control border-0 shadow-none bg-transparent pl-0" placeholder="Studio A: Half Day">
          </td>
          <td class="p-0 text-white font-weight-bold additional-cost align-middle">
            <input type="number" name="sp_service[sp_service_optionals][][price]" value="" class="form-control border-0 shadow-none bg-transparent pl-0" placeholder="Price">
          </td>
          <td class="text-right text-white pr-2">
            <i class="save-optional-extra" data-feather="save">
          </td>
        </tr>
      `

      $destination.append(option)
      feather.replace()
    })
  },

  handleSaveOptionalExtra(){
    $('.edit-service-modal').on('click', '.save-optional-extra', function(e){
      let $destination = $(e.currentTarget).parents('tr.extra-optional')
      let name = $(e.currentTarget).parents('td').siblings('.service-name').find('input').val()
      let price = $(e.currentTarget).parents('td').siblings('.additional-cost').find('input').val()

      if (name && price > 0) {
        let option = `
          <td class="p-0 text-white font-weight-bold service-name w-25 align-middle">
            <input type="hidden" name="sp_service[sp_service_optionals][][name]" value="${name}">
            <span class="name">${name}</span>
          </td>
          <td class="p-0 text-white font-weight-bold additional-cost align-middle">
            <input type="hidden" name="sp_service[sp_service_optionals][][price]" value="${price}">
            <span>+€</span>
            <span class="price">${price}</span>
          </td>
          <td class="text-right text-white pr-2">
            <i class="edit-optional-extra mr-2" data-feather="edit"></i>
            <i class="delete-optional-extra" data-feather="trash-2"></i>
          </td>
        `

        $destination.html(option)
        feather.replace()
      } else {
        alert('Please enter needed fields.')
      }

    })
  },

  handleEditOptionalExtra() {
    $('.edit-service-modal').on('click', '.edit-optional-extra', function(e) {
      let $destination = $(e.currentTarget).parents('tr.extra-optional')
      let name = $(e.currentTarget).parents('td').siblings('.service-name').find('span.name').text()
      let price = $(e.currentTarget).parents('td').siblings('.additional-cost').find('span.price').text()

      let option = `
        <td class="p-0 text-white font-weight-bold service-name w-25">
          <input type="text" name="sp_service[sp_service_optionals][][name]" value="${name}" class="form-control border-0 shadow-none bg-transparent pl-0" placeholder="Studio A: Half Day">
        </td>
        <td class="p-0 text-white font-weight-bold additional-cost">
          <input type="number" name="sp_service[sp_service_optionals][][price]" value="${price}" class="form-control border-0 shadow-none bg-transparent pl-0" placeholder="Price">
        </td>
        <td class="text-right text-white pr-2">
          <i class="save-optional-extra" data-feather="save">
        </td>
      `



      $destination.html(option)
      feather.replace()
    })
  },

  handleDeleteOptionalExtra() {
    $('.edit-service-modal').on('click', '.delete-optional-extra', function(e) {
      $(e.currentTarget).closest('.extra-optional').remove()
    })
  },

  handleToggleStartTime() {
    $('.edit-service-modal').on('change', 'input[type=checkbox]', function(e) {
      if($(e.currentTarget).prop('checked')) {
        $(e.currentTarget).parent().siblings('.add-another-start-time-section, .group-add-another').removeClass('d-none')
      } else {
        $(e.currentTarget).parent().siblings('.add-another-start-time-section, .group-add-another').addClass('d-none')
      }
    })
  },

  handleAddSpProfileVideo() {
    $('#add-video-btn').on('click', function(e) {
      if ($('.video-link-list tr').length < 5) {
        let link = $('#addVideoModal').find('input').val()

        let videoLink = `
          <tr>
            <td class="p-0 text-white font-weight-bold link-wrapper">
              <input type="hidden" value="${link}" name="sp_profile_videos[embed_urls][]">
              <p class="link">${link}</p>
            </td>
            <td class="action text-right text-white p-0 align-middle">
              <i class="edit-video-link far fa-edit ml-12" data-toggle="modal" data-target="#updateVideoModal"></i>
              <i class="delete-video-link far fa-trash-alt ml-12"></i>
            </td>
          </tr>
        `

        $('.video-link-list').append(videoLink)
        feather.replace()
        $('#addVideoModal').find('input').val('')
        $('#addVideoModal').modal('hide')
        $('.modal-backdrop').remove()
      } else {
        alert('Service provider videos cannot be more than 5.')
      }
    })
  },

  handleEditSpProfileVideo() {
    $('.section-profile-basics').on('click', '.edit-video-link', function(e){
      let $destination = $(e.currentTarget)
      let link = $destination.parents('td').siblings('.link-wrapper').find('input').val()

      $('#updateVideoModal input').val(link)

      $('#update-video-btn').off('click').on('click', function(e) {
        let updatedLink = $('#updateVideoModal input').val()
        $destination.parents('td').siblings('.link-wrapper').find('input').val(updatedLink)
        $destination.parents('td').siblings('.link-wrapper').find('p.link').text(updatedLink)
        $('#updateVideoModal').modal('hide')
        $('.modal-backdrop').remove()
      })
    })
  },

  handleDeleteSpProfileVideo() {
    $('.section-profile-basics').on('click', '.delete-video-link', function(e){
      $(e.currentTarget).parents('tr').remove()
    })
  },

  initCategoriesSelector() {
    $('#sp_profile_categories').select2({
      dropdownCssClass: "option-categories",
      containerCssClass: "container-categories",
      selectionCssClass: "select-categories",
      placeholder: "Select an option",
      multiple: true,
      closeOnSelect : false,
      tags: true
    });
  },
}
