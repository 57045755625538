window.uploadShowreel = {
  init() {
    this.handleAddSpProfileVideo();
    this.handleEditSpProfileVideo();
    this.handleDeleteSpProfileVideo();
  },

  handleAddSpProfileVideo() {
    $('#add-video-btn').on('click', function(e) {
      if ($('.video-link-list tr').length < 5) {
        let link = $('#addVideoModal').find('input').val()

        let videoLink = `
          <tr>
            <td class="p-0 text-white font-weight-bold link-wrapper">
              <input type="hidden" value="${link}" name="sp_profile_videos[embed_urls][]">
              <p class="link">${link}</p>
            </td>
            <td class="action text-right text-white p-0 align-middle">
              <i class="edit-video-link far fa-edit ml-12" data-toggle="modal" data-target="#updateVideoModal"></i>
              <i class="delete-video-link far fa-trash-alt ml-12"></i>
            </td>
          </tr>
        `

        $('.video-link-list').append(videoLink)
        feather.replace()
        $('#addVideoModal').find('input').val('')
        $('#addVideoModal').modal('hide')
        $('.modal-backdrop').remove()
      } else {
        alert('Service provider videos cannot be more than 5.')
      }
    })
  },

  handleEditSpProfileVideo() {
    $('.upload-showreel-steps').on('click', '.edit-video-link', function(e){
      let $destination = $(e.currentTarget)
      let link = $destination.parents('td').siblings('.link-wrapper').find('input').val()

      $('#updateVideoModal input').val(link)

      $('#update-video-btn').off('click').on('click', function(e) {
        let updatedLink = $('#updateVideoModal input').val()
        $destination.parents('td').siblings('.link-wrapper').find('input').val(updatedLink)
        $destination.parents('td').siblings('.link-wrapper').find('p.link').text(updatedLink)
        $('#updateVideoModal').modal('hide')
        $('.modal-backdrop').remove()
      })
    })
  },

  handleDeleteSpProfileVideo() {
    $('.upload-showreel-steps').on('click', '.delete-video-link', function(e){
      $(e.currentTarget).parents('tr').remove()
    })
  }
}