window.ChangePassword = {
  init() {
    this.validateForm();
  },

  validateForm() {
    $("#change-password-form").validate({
      errorClass: "is-invalid",
      rules: {
        "user[password]": {
          required: true,
          minlength: 8,
          check_password: /((?=.*\d)(?=.*[A-Z])(?=.*[a-z]))/
        },
        "user[password_confirmation]": {
          required: true,
          minlength: 8,
          equalTo : "#user_password"
        }
      },
      errorPlacement: function(error, element) {
        error.insertAfter(element);
      }
    });
  }
};