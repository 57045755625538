import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import momentPlugin from '@fullcalendar/moment';
import moment from 'moment';

window.Calendar = {
  init(booking_data, initialDate) {
    this.buildCalendar(booking_data, initialDate || (new Date()));

    this.handleSelectAllMarkedAvailability()
  },

  handleSelectAllMarkedAvailability() {
    $("#markUnavailabilityServicesModal #selectAll").on('click', function () {
      $("input[type=checkbox]").prop("checked", $(this).prop("checked"));
    });

    $("#markUnavailabilityServicesModal input[type=checkbox]").on('click', function () {
      if (!$(this).prop("checked")) {
        $("#selectAll").prop("checked", false);
      }
    })
  },

  buildCalendar(booking_data, initialDate) {
    const calendarEl = document.getElementById('calendar');
    const calendar = new Calendar(calendarEl, {
      plugins: [timeGridPlugin, momentPlugin, interactionPlugin],
      editable: true,
      initialDate: initialDate,
      initialView: window.innerWidth >= 768 ? 'timeGridFiveDay' : 'timeGridTwoDay',
      views: {
        timeGridFiveDay: {
          type: 'timeGrid',
          duration: { days: 5 },
          buttonText: '5 days'
        },
        timeGridTwoDay: {
          type: 'timeGrid',
          duration: { days: 2 },
          buttonText: '2 days'
        }
      },
      themeSystem: 'bootstrap',
      headerToolbar: {
        left: null,
        right: 'prev,next',
        center: 'title'
      },
      weekNumbers: true,
      dayMaxEvents: true,
      events: booking_data,
      contentHeight: 'auto',
      allDaySlot: false,
      expandRows: true,
      locale: 'en-GB',
      slotLabelFormat: {
        hour: '2-digit',
        minute: '2-digit',
        omitZeroMinute: false,
      },
      slotLabelInterval: 30,
      slotMinTime: '07:00:00',
      weekNumberContent: function (arg) {
        return {
          html: '<i class="fas fa-clock"></i>'
        }
      },
      dayHeaderContent: function (arg) {
        const date = moment(arg.date).format('DD MMM');
        const weekDay = moment(arg.date).format('ddd');
        const html = `
          <div class='fc header date'>${date}</div>
          <div class='fc header weekday'>${weekDay}</div>
        `
        return {
          html: html
        }
      },
      eventContent: function (arg) {
        const hours = Math.round(moment.duration(moment(arg.event.end).diff(moment(arg.event.start))).asHours());
        const start = moment(arg.event.start).format('HH:mm');
        const end = moment(arg.event.end).format('HH:mm');
        const html = `
          <div class="fc-event-main-frame px-2 pb-2">
            <div class="fc-event-time mt-2">${start} - ${end} (${hours} hours)</div>
            <div class="fc-event-title-container">
              <div class="fc-event-title fc-sticky my-1">${arg.event.title}</div>
              <div class="event-service fc-sticky mb-1">${arg.event.extendedProps.service_name}</div>
            </div>
          </div>
        `;

        return { html: html }
      },
      dateClick: function (info) {
        $('#markUnavailabilityServicesModal #bookingSlotDate').val(info.dateStr.split('T')[0])
        $('#markUnavailabilityServicesModal').modal('show')
      },
      eventClick: function(info) {
        if (info.event.extendedProps.status === 'blocked') {
          $('#markAvailabilityServicesModal form').attr('action', `/booking_slots/${info.event.id}`)
          $('#markAvailabilityServicesModal').modal('show')
        }
      }
    });
    calendar.render();
  }
};
