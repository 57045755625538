window.projectFiles = {
  init() {
    this.initServiceCategoriesSelect2();
    this.handleSubmitWhenSelectService();
    this.autofillFileNameOnSelectFile();
    this.handleSubmitUploadFileForm();
  },

  initServiceCategoriesSelect2() {
    $('#service_options, #project_service').select2({
      dropdownCssClass: "single-option",
      containerCssClass: "container-categories",
      selectionCssClass: "select-categories",
      placeholder: {
        id: '',
        text: "Select an option"
      }
    });
  },

  handleSubmitWhenSelectService() {
    $('#service_options').on('select2:select', function (e) {
      $('#search-form').submit();
    });
  },

  autofillFileNameOnSelectFile() {
    $('#file').on('change', function(e) {
      $('#project_file_file_name').val($(e.currentTarget).val().split('\\').pop().split('.').shift());
    })
  },

  handleSubmitUploadFileForm() {
    addEventListener("direct-upload:initialize", event => {
      const { target, detail } = event;
      const { id, file } = detail;
      $('#upload-file-btn').prop('disabled', true);
      $('#project_file_service_name').val($('#project_service option:selected').text());
      let fname = file.name;
      $('#project_file_file_type').val(fname.slice((fname.lastIndexOf(".") - 1 >>> 0) + 2));
    });

    addEventListener("direct-upload:start", event => {
      const { id } = event.detail;
      $('.file-upload-progress').removeClass('d-none');
    });

    addEventListener("direct-upload:progress", event => {
      const { id, progress } = event.detail;
      $('.progress-bar').css('width', progress+'%').attr('aria-valuenow', progress);
    });

    addEventListener("direct-upload:error", event => {
      event.preventDefault();
      $('.file-upload-progress').addClass('d-none');
      const { id, error } = event.detail
      alert(error);
    });

    addEventListener("direct-upload:end", event => {
      const { id } = event.detail
      $('.file-upload-progress').addClass('d-none');
    });
  }
}
